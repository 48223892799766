import React from 'react';
import { Box, Grid, Card, CardHeader, CardContent, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import EditAccountForm from '../components/EditAccountForm';
import { useAccount } from '../hooks/useAccount';

export default function Account() {
  const { t } = useTranslation();
  const {
    account: { id }
  } = useAccount();

  return (
    <Page title={t('pages.account')}>
      <Container>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{t('general.account')}</Typography>
        </Box>
        <Box
          style={{
            paddingTop: '16px'
          }}
        >
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card
                sx={{
                  '& .MuiTimelineItem-missingOppositeContent:before': {
                    display: 'none'
                  }
                }}
              >
                <CardHeader title={t('account.settings')} />
                <CardContent>
                  <EditAccountForm id={id} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
