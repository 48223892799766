import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import settingsFill from '@iconify/icons-eva/settings-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'overview',
    path: '/dashboard/overview',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'products',
    path: '/dashboard/products',
    icon: getIcon(shoppingBagFill)
  },
  // {
  //   title: 'playground',
  //   path: '/dashboard/playground',
  //   icon: getIcon(playCircleFill)
  // },
  // {
  //   title: 'billing',
  //   path: '/dashboard/billing',
  //   icon: getIcon(fileRemoveFill)
  // },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: getIcon(settingsFill)
  }
  // {
  //   title: 'account',
  //   path: '/dashboard/account'
  // },
  // {
  //   title: 'login',
  //   path: '/login'
  // },
  // {
  //   title: 'sign up',
  //   path: '/signup'
  // },
  // {
  //   title: 'confirm signup',
  //   path: '/confirm-signup'
  // },
  // {
  //   title: 'forgot password',
  //   path: '/forgot-password'
  // },
  // {
  //   title: 'Not found',
  //   path: '/404'
  // }
];

export default sidebarConfig;
