import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Button, Alert, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';

const noAccountAssignedErrors = [
  'K tomuto účtu není přiřazen žádný eshop. Prosím nainstalujte si doplněk na Shoptetu. Pokud jste si doplňek již nainstalovali, zkontrolujte, že se přihlašujete emailem, na který je váš eshop registrován.',
  'There is no eshop assigned to this account. Please install plugin for your eshop.-x',
  'There is no eshop assigned to this account. Please install plugin for your eshop.'
];

function FullscreenError({ message }) {
  const { t } = useTranslation();
  const { handleSignOut } = useAuth();

  const handleSignoutAndRedirectToShoptetClick = async () => {
    handleSignOut();
    // TODO: this is super nasty :/
    window.location.href = 'https://doplnky.shoptet.cz/recommender';
  };

  return (
    <Backdrop
      sx={{
        // color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: 'column',
        background: `#fff`
      }}
      open
    >
      <Alert severity="error">{message}</Alert>
      {noAccountAssignedErrors.includes(message) && (
        <Box>
          <Button
            onClick={handleSignoutAndRedirectToShoptetClick}
            size="large"
            variant="contained"
            sx={{
              mt: 2,
              mb: 2
            }}
          >
            {t('signup.install_shoptet')}
          </Button>
        </Box>
      )}
      {/* <Box
        component="img"
        src="/static/logo.svg"
        sx={{ filter: 'invert(0.25)', height: '2rem', marginBottom: '1rem' }}
      />
      <CircularProgress
        color="inherit"
        sx={{
          opacity: 0.25
        }}
      /> */}
    </Backdrop>
  );
}

FullscreenError.propTypes = {
  message: PropTypes.string.isRequired
};

export default FullscreenError;
