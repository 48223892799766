import React from 'react';
import { Backdrop, Box } from '@material-ui/core';
import { keyframes } from '@mui/system';

const pulse = keyframes`
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
`;

function FullscreenLoader() {
  return (
    <Backdrop
      sx={{
        // color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: 'column',
        background: `#fff`
      }}
      open
    >
      <Box
        component="img"
        src="/static/rec_icon02.svg"
        sx={{ height: '6rem', marginBottom: '1rem', animation: `${pulse} 2s infinite ease` }}
      />
    </Backdrop>
  );
}

export default FullscreenLoader;
