import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Card, Link, Container, Typography } from '@material-ui/core';
// layouts
import { useTranslation } from 'react-i18next';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function SignUp() {
  const { t } = useTranslation();

  // const navigate = useNavigate();
  // const handleSubmitSuccess = ({ username }) => {
  //   navigate({
  //     pathname: '/confirm-signup',
  //     search: `?username=${username}`
  //   });
  // };

  return (
    <RootStyle title={t('pages.signup')}>
      <AuthLayout>
        {t('signup.have_and_account')}&nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          {t('login.login')}
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {t('signup.manage_text')}
          </Typography>
          <img alt="register" src="/static/illustrations/illustration_recommender.jpg" />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('signup.install_plugin_for_platform')}
            </Typography>
            <Button
              href="https://doplnky.shoptet.cz/recommender"
              target="_blank"
              size="large"
              variant="contained"
              sx={{
                mt: 2,
                mb: 2
              }}
            >
              {t('signup.install_shoptet')}
            </Button>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('signup.install_plugin_for_platform_description')}
            </Typography>
          </Box>

          {/* <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('signup.get_started_text')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('signup.free_tier_text')} {t('signup.no_card_required_text')}
            </Typography>
          </Box>

          <SignUpForm onSubmitSuccess={handleSubmitSuccess} />

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            {t('signup.manage_text')} {t('signup.i_agree_terms_and_conditions')}&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              {t('signup.terms_and_service')}
            </Link>
            &nbsp;{t('signup.and')}and&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              {t('signup.privacy_policy')}
            </Link>
            .
          </Typography> */}

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              {t('signup.have_and_account')}&nbsp;
              <Link to="/login" component={RouterLink}>
                {t('login.login')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
