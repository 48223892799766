import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useAccount } from '../hooks/useAccount';
import Label from './Label';
import LearningProgress from './LearningProgress';
import { statusColor } from '../utils/variables';
import HelperTooltip from './HelperTooltip';

function EshopMedallionMini() {
  const { eshop } = useAccount();
  const { t } = useTranslation();

  return (
    <Box sx={{ ml: 0, width: '100%' }}>
      <Typography variant="h6" sx={{ color: 'text.primary' }}>
        {`${eshop.name ? eshop.name : eshop.domain}`}
      </Typography>
      <Typography variant="h8" sx={{ color: 'text.secondary' }}>
        {eshop.domain}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Label
          color={statusColor(eshop.status)}
          sx={{
            mt: 1,
            mb: 1
          }}
        >
          {t('eshop.status', {
            context: eshop.status.toLowerCase()
          })}
          <HelperTooltip title={t(`eshop.status_${eshop.status.toLowerCase()}_tooltip`)} />
        </Label>

        {eshop.status.toLowerCase() === 'connected' && <LearningProgress domain={eshop.domain} />}
      </Box>
    </Box>
  );
}

export default EshopMedallionMini;
