import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// material
import { Card, Typography, CardHeader, CardContent } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot,
  Skeleton
} from '@material-ui/lab';
import { useQuery, gql } from '@apollo/client';
import { statusValue } from '../utils/variables';

OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

function OrderItem({ item, isLast }) {
  const { t } = useTranslation();
  const { title, isDone } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor: isDone ? 'success.main' : 'default.main'
          }}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">
          {t('settings.status', {
            context: title
          })}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {isDone ? t('settings.done') : t('settings.waiting')}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

const GET_ESHOP_INFO = gql`
  query getEshopInfo($id: ID!, $domain: ID!) {
    eshop: getEshop(id: $id) {
      id
      status
    }
    products: listProducts(domain: $domain, limit: 1) {
      items {
        domain
      }
    }
  }
`;

function calculateStatus(data) {
  const {
    eshop: { status },
    products: { items }
  } = data;
  return [
    {
      title: 'eshop_verified',
      isDone: statusValue(status) >= statusValue('verified')
    },
    {
      title: 'products_downloaded',
      isDone: items.length > 0
    },
    {
      title: 'tracking',
      isDone: statusValue(status) >= statusValue('connected')
    },
    {
      title: 'recommending',
      isDone: statusValue(status) >= statusValue('recommending')
    }
  ];
}

function EshopStatus({ id, domain }) {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_ESHOP_INFO, {
    variables: {
      id,
      domain
    }
  });

  if (error) return null;
  const statusItems = data ? calculateStatus(data) : [];

  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        }
      }}
    >
      <CardHeader title={t('settings.installation_status')} />
      <CardContent>
        {loading &&
          [...Array(4)].map((_, i) => (
            <Typography variant="subtitle2" key={i}>
              <Skeleton />
            </Typography>
          ))}
        {statusItems && (
          <Timeline
            sx={{
              '& .MuiTimelineItem-missingOppositeContent:before': {
                display: 'none'
              }
            }}
          >
            {statusItems.map((item, index) => (
              <OrderItem key={item.title} item={item} isLast={index === statusItems.length - 1} />
            ))}
          </Timeline>
        )}
      </CardContent>
    </Card>
  );
}

EshopStatus.propTypes = {
  id: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired
};

export default EshopStatus;
