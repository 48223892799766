export const platforms = [
  {
    value: 'shoptet',
    name: 'shoptet',
    disabled: false
  },
  {
    value: 'upgates',
    name: 'upgates',
    disabled: false
  },
  {
    value: 'woocommerce',
    name: 'woocommerce',
    disabled: true
  },
  {
    value: 'shopify',
    name: 'shopify',
    disabled: true
  }
];

const statuses = [
  'unconfirmed',
  'verified',
  'connected',
  'ready',
  'recommending',
  'paused',
  'deleted',
  'failed'
];

export const statusValue = (status) => {
  const s = status.toLowerCase();
  return statuses.findIndex((el) => el === s);
};

export const statusColor = (status) => {
  const s = status.toLowerCase();
  switch (s) {
    case 'unconfirmed':
      return 'default';
    case 'verified':
    case 'connected':
    case 'ready':
      return 'info';
    case 'recommending':
      return 'success';
    case 'paused':
      return 'warning';
    case 'deleted':
    case 'failed':
      return 'error';
    default:
      return 'default';
  }
};
