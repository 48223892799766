import { useTranslation } from 'react-i18next';
// material
import { Typography, Alert, Skeleton } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import { useAccount } from '../hooks/useAccount';
import AccountForm from './AccountForm';

const GET_ACCOUNT = gql`
  query getAccount($id: ID!) {
    getAccount(id: $id) {
      id
      username: email
    }
  }
`;

// ----------------------------------------------------------------------

function EditAccountForm() {
  const { t } = useTranslation();
  const {
    account: { id }
  } = useAccount();
  const { data, loading, error } = useQuery(GET_ACCOUNT, {
    variables: {
      id
    }
  });

  if (error) {
    return <Alert severity="error">{t(`errors.failed_to_load_account`)}</Alert>;
  }
  if (loading) {
    return (
      <div>
        {[...Array(2)].map((e, i) => (
          <Typography variant="h1" key={i}>
            <Skeleton />
          </Typography>
        ))}
      </div>
    );
  }

  return <AccountForm defaultValues={{ ...data.getAccount }} />;
}

export default EditAccountForm;
