// routes
import Amplify from 'aws-amplify';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastContainer } from 'react-toastify';
import createApolloClient from './utils/apolloClient';
import { AuthProvider } from './hooks/useAuth';
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import 'react-toastify/dist/ReactToastify.css';

import config from './aws-exports';

Amplify.configure(config);

const client = createApolloClient({
  uri: config.aws_appsync_graphqlEndpoint,
  cache: true
});

export default function App() {
  return (
    <ThemeConfig>
      <ToastContainer hideProgressBar />
      <ScrollToTop />
      <ApolloProvider client={client}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </ApolloProvider>
    </ThemeConfig>
  );
}
