import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import personOutline from '@iconify/icons-eva/person-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography, Tooltip, IconButton } from '@material-ui/core';
// components
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../hooks/useAccount';
import MenuPopover from '../../components/MenuPopover';
import { signOut } from '../../utils/auth';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'account.settings',
    icon: settings2Fill,
    linkTo: '/dashboard/account'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { t } = useTranslation();
  const {
    account: { email }
  } = useAccount();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <>
      <Tooltip title={t('general.user')}>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            })
          }}
        >
          <Icon icon={personOutline} />
          {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {t('general.current_user')}
          </Typography>
          <Typography variant="subtitle1" noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {t(option.label)}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {t('general.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
