import { useSearchParams, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Stack, Container, Typography, CircularProgress } from '@material-ui/core';
// layouts
import { useTranslation } from 'react-i18next';
import LoginForm from '../components/LoginForm';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const password = searchParams.get('password');
  const autoLogin = searchParams.get('autoLogin') === 'true';
  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    navigate({
      pathname: '/'
    });
  };

  return (
    <RootStyle title={t('pages.login')}>
      <AuthLayout>Logging in for Upgates</AuthLayout>

      {autoLogin && (
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Logging in for Upgates...
              </Typography>
            </Stack>

            <CircularProgress />

            <LoginForm
              defaultUsername={username.replaceAll(' ', '+')}
              defaultPassword={password}
              autoLogin={autoLogin}
              onSubmitSuccess={handleSubmitSuccess}
              isHidden
            />
          </ContentStyle>
        </Container>
      )}

      {!autoLogin && (
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Page not found
              </Typography>
            </Stack>
          </ContentStyle>
        </Container>
      )}
    </RootStyle>
  );
}
