import { Box, TextField } from '@material-ui/core';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { momentObj } from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function PeriodSelector({ from, to, range, dispatch }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '2rem',
        justifyContent: 'space-between'
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={range}
        exclusive
        onChange={(v) => dispatch({ type: `set_${v.target.value}` })}
        aria-label="range"
        size="large"
        sx={{
          marginRight: '1rem'
        }}
      >
        <ToggleButton value="7_days">{t('overview.last_7_days')}</ToggleButton>
        <ToggleButton value="last_month">{t('overview.last_month')}</ToggleButton>
        <ToggleButton value="this_month">{t('overview.this_month')}</ToggleButton>
        <ToggleButton value="custom">{t('overview.custom')}</ToggleButton>
      </ToggleButtonGroup>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          opacity: 0.5
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              marginRight: '1rem'
            }}
          >
            <DesktopDatePicker
              disabled={range !== 'custom'}
              label={t('overview.from')}
              inputFormat="DD.MM.YYYY"
              value={from}
              maxDate={moment().subtract(1, 'd')}
              onChange={(v) => dispatch({ type: 'set_from', from: v })}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box>
            <DesktopDatePicker
              disabled={range !== 'custom'}
              label={t('overview.to')}
              inputFormat="DD.MM.YYYY"
              value={to}
              maxDate={moment()}
              minDate={from}
              onChange={(v) => dispatch({ type: 'set_to', to: v })}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>
      </Box>
    </Box>
  );
}

PeriodSelector.propTypes = {
  from: momentObj,
  to: momentObj,
  range: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default PeriodSelector;
