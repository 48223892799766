import { Grid, Container, Alert, Skeleton, useTheme, Typography, Box } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import usePeriod from '../hooks/usePeriod';
import PeriodSelector from '../components/PeriodSelector';
import { useAccount } from '../hooks/useAccount';
import SingleChart from '../components/SingleChart';
import MetricCard from '../components/MetricCard';
import Page from '../components/Page';
import RecommendationsChart from '../components/RecommendationsChart';
import ContainerMessage from '../components/ContainerMessage';

function average(items) {
  return items.reduce((prev, current) => prev + current, 0) / items.length;
}

function divide(a, b) {
  if (a / b === Infinity) {
    return false;
  }
  return a / b;
}

function calculateStatistics(items) {
  if (items.length < 1) {
    return null;
  }
  const labels = [];
  const uniqueVisitors = [];
  const recommendations = [];
  const recoViewsRate = [];
  const addedToCartRates = [];
  const productViews = [];
  const addedToCart = [];
  const recommendedAddedToCart = [];
  const recommendedOrders = [];
  const ordered = [];
  const recoToViewsShare = [];
  const recoAddedToAddedShare = [];
  const recommendedOrderShare = [];

  items.forEach((item) => {
    labels.push(item.dateKey || '-');
    uniqueVisitors.push(item.uniqueVisitors || 0);
    recommendations.push(item.recommendations || 0);
    recoViewsRate.push(divide(item.recommendedProductViews, item.recommendations) || 0);
    addedToCartRates.push(divide(item.recommendedAddedToCart, item.addedToCart) || 0);
    recommendedOrderShare.push(divide(item.recommendedOrders, item.orders) || 0);
    productViews.push(item.productViews || 0);
    recommendedAddedToCart.push(item.recommendedAddedToCart || 0);
    addedToCart.push(item.addedToCart || 0);
    ordered.push(item.orders || 0);
    recommendedOrders.push(item.recommendedOrders || 0);
    recoToViewsShare.push(divide(item.recommendedProductViews, item.productViews) || 0);
    recoAddedToAddedShare.push(divide(item.recommendedAddedToCart, item.addedToCart) || 0);
  });

  const totalUniqueVisitors = uniqueVisitors.reduce((prev, curr) => prev + curr, 0);
  const totalRecommendations = recommendations.reduce((prev, curr) => prev + curr, 0);
  const averageRecoViewsRate = average(recoViewsRate);
  const averageAddedToCartRate = average(addedToCartRates);
  const averageRecommendedOrderShare = average(recommendedOrderShare);

  return {
    labels,
    uniqueVisitors,
    recommendations,
    addedToCartRates,
    totalUniqueVisitors,
    totalRecommendations,
    averageRecoViewsRate,
    averageAddedToCartRate,
    averageRecommendedOrderShare,
    productViews,
    addedToCart,
    recommendedAddedToCart,
    ordered,
    recoToViewsShare,
    recoAddedToAddedShare,
    recommendedOrders,
    recommendedOrderShare
  };
}

const GET_STATISTICS = gql`
  query MyQuery($domain: ID!, $from: String!, $to: String!) {
    listStatistics(domain: $domain, typeDateKey: { between: [$from, $to] }) {
      items {
        addedToCart
        createdAt
        dateKey
        orders
        productViews
        recommendations
        recommendedAddedToCart
        recommendedOrders
        recommendedProductViews
        type
        typeDateKey
        uniqueVisitors
        updatedAt
      }
    }
  }
`;

export default function Overview() {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    eshop: { domain }
  } = useAccount();
  const { from, to, range, dispatch: dispatchPeriod } = usePeriod();
  const { loading, error, data } = useQuery(GET_STATISTICS, {
    variables: {
      domain,
      from: `daily-${from.clone().format('YYYY-MM-DD')}`,
      to: `daily-${to.clone().format('YYYY-MM-DD')}`
    }
  });
  const statistics = data ? calculateStatistics(data.listStatistics.items) : null;

  return (
    <Page title={t('pages.overview')}>
      <Container>
        <Box>
          <Typography variant="h4" sx={{ mb: 5 }}>
            {t('general.overview')}
          </Typography>
        </Box>
        <Box>
          <PeriodSelector from={from} to={to} range={range} dispatch={dispatchPeriod} />
        </Box>
        {error && <Alert severity="error">{t(`errors.failed_to_load_overview`)}</Alert>}
        {loading && (
          <>
            <Grid container spacing={3}>
              {[...Array(4)].map((_, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Skeleton
                    height={175}
                    variant="rectangular"
                    sx={{
                      borderRadius: `${theme.shape.borderRadius}px`
                    }}
                  />
                </Grid>
              ))}
              {[...Array(2)].map((_, index) => (
                <Grid item xs={12} md={12} lg={12} key={index}>
                  <Skeleton
                    height={450}
                    variant="rectangular"
                    sx={{
                      borderRadius: `${theme.shape.borderRadius}px`
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {!statistics && (
          <ContainerMessage
            title={t('overview.not_enough_data_title')}
            subtitle={t('overview.not_enough_data_description')}
          />
        )}
        {statistics && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <MetricCard
                title={t('overview.number_of_visitors')}
                number={statistics.totalUniqueVisitors}
                tooltip={t('overview.number_of_visitors_tooltip')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MetricCard
                title={t('overview.number_of_recommendations')}
                number={statistics.totalRecommendations}
                tooltip={t('overview.number_of_recommendations_tooltip')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MetricCard
                title={t('overview.ctr')}
                number={statistics.averageRecoViewsRate}
                isPercentage
                tooltip={t('overview.ctr_tooltip')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MetricCard
                title={t('overview.atcr')}
                number={statistics.averageAddedToCartRate}
                isPercentage
                tooltip={t('overview.actr_tooltip')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <MetricCard
                title={t('overview.reco_order')}
                number={statistics.averageRecommendedOrderShare}
                isPercentage
                tooltip={t('overview.reco_order_tooltip')}
              />
            </Grid>
            {/* CHARTS */}
            <Grid item xs={12} md={12} lg={12}>
              <RecommendationsChart
                title={t('overview.recommendations_and_visitors')}
                productViews={statistics.productViews}
                recommendations={statistics.recommendations}
                labels={statistics.labels}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SingleChart
                title={t('overview.reco_order_share_title')}
                seriesLabel={t('overview.reco_order_share')}
                dataSeries={statistics.recommendedOrderShare}
                labels={statistics.labels}
                isPercentage
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SingleChart
                title={t('overview.reco_to_views_share_title')}
                seriesLabel={t('overview.reco_to_views_share')}
                dataSeries={statistics.recoToViewsShare}
                labels={statistics.labels}
                isPercentage
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SingleChart
                title={t('overview.reco_added_to_added_share_title')}
                seriesLabel={t('overview.reco_added_to_added_share')}
                dataSeries={statistics.recoAddedToAddedShare}
                labels={statistics.labels}
                isPercentage
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
