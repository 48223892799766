import PropTypes from 'prop-types';
import { Card, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HelperTooltip from './HelperTooltip';

function MetricCard({ title, number, isPercentage, tooltip }) {
  const { t } = useTranslation();

  return (
    <Card>
      <Box
        sx={{
          p: 3,
          pb: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
        dir="ltr"
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              mb: '16px'
            }}
          >
            {title}
            {tooltip && <HelperTooltip title={tooltip} />}
          </Typography>
          <Typography variant="h3">
            {isPercentage
              ? `${parseFloat(number * 100).toFixed(2)}%`
              : t('general.intlNumber', { val: number })}
          </Typography>
        </Box>
        {/* <MetricsChart /> */}
      </Box>
    </Card>
  );
}

MetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  isPercentage: PropTypes.bool,
  tooltip: PropTypes.string
};

MetricCard.defaultProps = {
  isPercentage: false,
  tooltip: null
};

export default MetricCard;
