import { motion } from 'framer-motion';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Typography, Container } from '@material-ui/core';
// components
import { useTranslation } from 'react-i18next';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function EshopNotFound() {
  const { t } = useTranslation();
  return (
    <RootStyle title={t('pages.eshop_not_found')}>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                {t('eshop_not_found.no_eshop_found')}
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('eshop_not_found.install_plugin')}
            </Typography>

            <Button
              href="https://doplnky.shoptet.cz/recommender"
              target="_blank"
              size="large"
              variant="contained"
              sx={{
                mt: 2
              }}
            >
              {t('eshop_not_found.install')}
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
