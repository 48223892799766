import { useRef, useState } from 'react';
import { Box, MenuItem, ListItemText, Button, Tooltip, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import chevronDownIcon from '@iconify/icons-eva/chevron-down-outline';
import MenuPopover from '../../components/MenuPopover';
import { useAccount } from '../../hooks/useAccount';

// ----------------------------------------------------------------------

export default function EshopPopover() {
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { eshop, selectEshop, eshops } = useAccount();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = (id) => {
    selectEshop(id);
    setOpen(false);
  };

  if (!eshop) return null;

  return (
    <>
      <Tooltip title={t('general.eshop')}>
        <Button ref={anchorRef} onClick={handleOpen} endIcon={<Icon icon={chevronDownIcon} />}>
          <Typography
            variant="button"
            sx={{
              color: grey[900],
              textTransform: 'none'
            }}
          >
            {eshop.domain}
          </Typography>
        </Button>
      </Tooltip>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {eshops.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === eshop.id}
              onClick={() => handleClick(option.id)}
              sx={{ py: 1, px: 2.5 }}
            >
              {/* <ListItemIcon>
                <Box component="img" alt={option.name} src={`${option.url}/favicon.ico`} />
              </ListItemIcon> */}
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.domain}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
