import { Navigate, useRoutes } from 'react-router-dom';
// layoutt
import FullscreenLoader from './components/FullscreenLoader';
import { useAuth } from './hooks/useAuth';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Magic from './pages/Magic';
import SignUp from './pages/SignUp';
import Overview from './pages/Overview';
import Products from './pages/Products';
import Billing from './pages/Billing';
import Settings from './pages/Settings';
import NotFound from './pages/Page404';
import ConfirmSignUp from './pages/ConfirmSignUp';
import ForgotPassword from './pages/ForgotPassword';
import Account from './pages/Account';
import Playground from './pages/Playground';
import EshopNotFound from './pages/EshopNotFound';
import { AccountProvider } from './hooks/useAccount';

// ----------------------------------------------------------------------

export default function Router() {
  const { user, loading } = useAuth();
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: user ? (
        <AccountProvider>
          <DashboardLayout />
        </AccountProvider>
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { path: '/dashboard', element: <Navigate to="/dashboard/overview" replace /> },
        { path: '/dashboard/overview', element: <Overview /> },
        { path: '/dashboard/products', element: <Products /> },
        { path: '/dashboard/playground', element: <Playground /> },
        { path: '/dashboard/settings', element: <Settings /> },
        { path: '/dashboard/billing', element: <Billing /> },
        { path: '/dashboard/account', element: <Account /> },
        { path: '/dashboard/eshop-not-found', element: <EshopNotFound /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'magic', element: <Magic /> },
        { path: 'signup', element: <SignUp /> },
        { path: 'confirm-signup', element: <ConfirmSignUp /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);

  if (loading) {
    return <FullscreenLoader />;
  }

  return routes;
}
