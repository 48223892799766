import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Alert, Link, Stack, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useAuth } from '../hooks/useAuth';
import { i18nStr } from '../utils/i18nStr';

function LoginForm({
  onSubmitSuccess,
  defaultUsername,
  defaultPassword,
  autoLogin,
  isHidden = false
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      username: defaultUsername,
      password: defaultPassword
    }
  });
  const { handleSignIn } = useAuth();

  const onSubmit = useCallback(async () => {
    const { username, password } = getValues();

    setIsSubmitting(true);
    try {
      await handleSignIn({
        username,
        password
      });
      setSubmitError(null);
      onSubmitSuccess();
      reset();
    } catch (error) {
      setSubmitError(error.message);
    }
    setIsSubmitting(false);
  }, [handleSignIn, onSubmitSuccess, reset, getValues]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (autoLogin === true) {
      onSubmit();
    }
  }, [autoLogin, onSubmit]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {submitError && <Alert severity="error">{t(`errors.${i18nStr(submitError)}`)}</Alert>}

        <TextField
          {...register('username', {
            required: {
              value: true,
              message: t('form.required')
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t('form.must_be_valid_email')
            }
          })}
          onBlur={() => trigger('username')}
          fullWidth
          autoComplete="username"
          type="email"
          label={t('login.username')}
          placeholder={t('login.username_placeholder')}
          error={Boolean(errors?.username?.message)}
          helperText={errors?.username?.message}
          style={isHidden ? { display: 'none' } : { display: 'inline-flex' }}
        />
        <TextField
          {...register('password', {
            required: {
              value: true,
              message: t('form.required')
            }
          })}
          onBlur={() => trigger('password')}
          fullWidth
          autoComplete="password"
          type={showPassword ? 'text' : 'password'}
          label={t('login.password')}
          placeholder={t('login.password_placeholder')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(errors?.password?.message)}
          helperText={errors?.password?.message}
          style={isHidden ? { display: 'none' } : { display: 'inline-flex' }}
        />
      </Stack>

      {!isHidden && (
        <Stack
          direction="row-reverse"
          alignItems="right"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Link component={RouterLink} variant="subtitle2" to="/forgot-password">
            {t('login.forgot_password')}
          </Link>
        </Stack>
      )}

      {!isHidden && (
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('login.login')}
        </LoadingButton>
      )}
    </form>
  );
}

LoginForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  defaultUsername: PropTypes.string,
  defaultPassword: PropTypes.string,
  autoLogin: PropTypes.bool,
  isHidden: PropTypes.bool
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => {},
  defaultUsername: '',
  defaultPassword: '',
  autoLogin: false
};

export default LoginForm;
