import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Tooltip } from '@material-ui/core';
import infoOutline from '@iconify/icons-eva/info-outline';

function HelperTooltip({ title }) {
  return (
    <Tooltip title={title}>
      <Icon
        icon={infoOutline}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 4,
          opacity: 0.5
        }}
      />
    </Tooltip>
  );
}

HelperTooltip.propTypes = {
  title: PropTypes.string.isRequired
};

export default HelperTooltip;
