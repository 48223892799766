import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Drawer, Typography, Stack } from '@material-ui/core';
// components
import { useTranslation } from 'react-i18next';
import EshopMedallionMini from '../../components/EshopMedallionMini';
import { useAccount } from '../../hooks/useAccount';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { eshop } = useAccount();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        {eshop && (
          <AccountStyle>
            {/* <Avatar src={`${eshop.url}/favicon.ico`} alt="photoURL" /> */}
            <Box sx={{ ml: 0, width: '100%' }}>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <a target="_blank" href={eshop.url} rel="noreferrer">
                  {shorten(eshop.url, 30)}
                </a>
              </Typography> */}
              <EshopMedallionMini domain={eshop.domain} />
            </Box>
          </AccountStyle>
        )}
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          {/* <Box
            component="img"
            src="/static/illustrations/avatar.jpg"
            sx={
              {
                // filter:
              }
            }
            // sx={{ width: 100, position: 'absolute', top: 0 }}
          /> */}

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              {t('sidebar.need_some_help')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('sidebar.checkout_our')}{' '}
              <a
                href={`//www.recommender.cz#${eshop.platform}-plugin`}
                target="_blank"
                rel="noreferrer"
              >
                {t('sidebar.documentation')}
              </a>
              ,{' '}
              <a href="//www.youtube.com/@navidatorRecos" target="_blank" rel="noreferrer">
                {t('sidebar.video_tutorials')}
              </a>{' '}
              {t('sidebar.or_contact_us')}
            </Typography>
          </Box>

          <Button
            fullWidth
            href="mailto:recommender@navidator.com"
            target="_blank"
            variant="contained"
            sx={{
              textTransform: 'none'
            }}
          >
            {t('sidebar.contact_support')}
          </Button>
        </Stack>
      </Box>
      <Box sx={{ px: 2.5, pb: 3, display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: 'center',
            marginBottom: '0.5rem'
          }}
        >
          powered by
        </Typography>
        <Box component="img" src="/static/logo.svg" sx={{ filter: 'invert(1)', height: '1rem' }} />
      </Box>
    </Scrollbar>
  );

  if (!eshop) return null;

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
