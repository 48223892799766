import { Auth } from 'aws-amplify';

export const signIn = async (username, password) => {
  await Auth.signIn(sanitizeUsername(username), password);
};

export const signOut = async () => {
  await Auth.signOut();
};

export const forgotPassword = async (username) => {
  await Auth.forgotPassword(sanitizeUsername(username));
};

export const forgotPasswordSubmit = async (username, code, password) => {
  await Auth.forgotPasswordSubmit(sanitizeUsername(username), code, password);
};

export const signUp = async (username, password) => {
  const user = await Auth.signUp({
    username: sanitizeUsername(username),
    password
  });
  return user;
};

export const confirmSignUp = async (username, code) => {
  await Auth.confirmSignUp(sanitizeUsername(username), code);
};

export const getCurrentUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user;
};

function sanitizeUsername(username) {
  return username.toLowerCase();
}
