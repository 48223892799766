import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// material
import { Typography, Alert, Skeleton } from '@material-ui/core';
import { useQuery, gql, useMutation } from '@apollo/client';
import EshopForm from './EshopForm';

const GET_ESHOP = gql`
  query getEshop($id: ID!) {
    getEshop(id: $id) {
      id
      name
      feedUrl
      url
      platform
      status
      feedUpdateFrequency
      showRecommendations
    }
  }
`;

const UPDATE_ESHOP = gql`
  mutation updateEshop($input: UpdateEshopInput!) {
    updateEshop(input: $input) {
      id
      name
      feedUrl
      url
      platform
      status
      feedUpdateFrequency
      showRecommendations
    }
  }
`;

function EditEshopForm({ id }) {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_ESHOP, {
    variables: {
      id
    }
  });
  const [updateEshop, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ESHOP);

  const onSubmit = (input) =>
    updateEshop({
      variables: {
        input
      }
    });

  if (error) {
    return <Alert severity="error">{t(`errors.failed_to_load_eshop`)}</Alert>;
  }
  if (updateError) {
    return <Alert severity="error">{t(`errors.failed_to_update_eshop`)}</Alert>;
  }
  if (loading) {
    return (
      <div>
        {[...Array(5)].map((e, i) => (
          <Typography variant="h1" key={i}>
            <Skeleton />
          </Typography>
        ))}
      </div>
    );
  }

  const isReady = data?.getEshop?.status === 'RECOMMENDING';

  return (
    <EshopForm
      defaultValues={{
        ...data.getEshop
      }}
      loading={updateLoading}
      onSubmit={onSubmit}
      isReady={isReady}
    />
  );
}

EditEshopForm.propTypes = {
  id: PropTypes.string.isRequired
};

EditEshopForm.defaultProps = {};

export default EditEshopForm;
