import { useReducer } from 'react';
import moment from 'moment';

function reducer(state, action) {
  switch (action.type) {
    case 'set_from':
      return { ...state, from: action.from };
    case 'set_to':
      return { ...state, to: action.to };
    case 'set_7_days':
      return {
        ...state,
        from: moment().subtract(8, 'd'),
        to: moment().subtract(1, 'd'),
        range: '7_days'
      };
    case 'set_this_month':
      return {
        ...state,
        from: moment().startOf('month'),
        to: moment(),
        range: 'this_month'
      };
    case 'set_last_month':
      return {
        ...state,
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
        range: 'last_month'
      };
    case 'set_custom':
      return { ...state, range: 'custom' };
    default:
      throw new Error();
  }
}

function usePeriod() {
  const [state, dispatch] = useReducer(reducer, {
    from: moment().subtract(8, 'd'),
    to: moment().subtract(1, 'd'),
    range: '7_days'
  });

  return {
    ...state,
    dispatch
  };
}

export default usePeriod;
