import PropTypes from 'prop-types';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@material-ui/core';
//
import { useTranslation } from 'react-i18next';
import { BaseOptionChart } from './charts';

function RecommendationsChart({ title, subtitle, productViews, recommendations, labels }) {
  const { t } = useTranslation();

  const CHART_DATA = [
    {
      name: t('overview.number_of_product_views'),
      type: 'bar',
      data: productViews
    },
    {
      name: t('overview.number_of_recommendations'),
      type: 'bar',
      data: recommendations
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 0] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'solid'] },
    labels: [...labels],
    xaxis: { type: 'datetime' },
    // yaxis: [
    //   {
    //     title: {
    //       text: t('overview.number_of_product_views')
    //     }
    //   },
    //   {
    //     title: {
    //       text: t('overview.number_of_recommendations')
    //     }
    //   }
    // ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title={title} subheader={subtitle} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}

RecommendationsChart.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  productViews: PropTypes.arrayOf(PropTypes.number).isRequired,
  recommendations: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
};

RecommendationsChart.defaultProps = {
  subtitle: ''
};

export default RecommendationsChart;
