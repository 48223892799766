import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/react-hooks';
import { useAuth } from './useAuth';

const accountContext = createContext();
accountContext.displayName = 'AccountContext';

const LIST_ACCOUNTS = gql`
  query accountsByCognitoClientId($cognitoClientId: String!) {
    accountsByCognitoClientId(cognitoClientId: $cognitoClientId) {
      items {
        id
        cognitoClientId
        email
        phone
        createdAt
        eshops {
          items {
            id
            name
            url
            domain
            status
            platform
          }
        }
      }
    }
  }
`;

const initialState = {
  loading: true,
  error: undefined,
  account: undefined,
  eshop: undefined,
  eshops: []
};

const calculateData = (data) => {
  if (!data) {
    throw new Error('no data available');
  }
  if (data.accountsByCognitoClientId.items.length === 0) {
    throw new Error('no account assigned');
  }
  if (data.accountsByCognitoClientId.items.length >= 2) {
    throw new Error('corrupted account');
  }
  return {
    account: data.accountsByCognitoClientId.items[0],
    eshops: data.accountsByCognitoClientId.items[0].eshops.items || [],
    eshop: data.accountsByCognitoClientId.items[0].eshops.items[0] || null
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_DATA_INIT':
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case 'FETCH_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload.data
      };
    case 'FETCH_DATA_FAILURE':
      return { ...state, loading: false, error: action.payload.error };
    case 'ACCOUNT_ERROR':
      return { ...state, loading: false, error: action.payload.error };
    case 'SELECT_ESHOP':
      return { ...state, eshop: action.payload.eshop };
    default:
      throw new Error('unsupported action');
  }
};

function useProvideAccount() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const { error, loading, data } = useQuery(LIST_ACCOUNTS, {
    variables: {
      cognitoClientId: user?.username
    }
  });

  const selectEshop = (id) => {
    const foundEshop = state.eshops.find((e) => e.id === id);
    if (!foundEshop) {
      throw new Error(`eshop with id '${id}' not found`);
    }
    dispatch({
      type: 'SELECT_ESHOP',
      payload: {
        eshop: foundEshop
      }
    });
  };

  useEffect(() => {
    switch (true) {
      case error !== undefined:
        dispatch({
          type: 'FETCH_DATA_FAILURE',
          payload: {
            error
          }
        });
        break;
      case loading === true:
        dispatch({
          type: 'FETCH_DATA_INIT'
        });
        break;
      case data !== undefined:
        try {
          const d = calculateData(data);
          dispatch({
            type: 'FETCH_DATA_SUCCESS',
            payload: {
              data: d
            }
          });
        } catch (error) {
          dispatch({
            type: 'ACCOUNT_ERROR',
            payload: {
              error
            }
          });
        }
        break;
      default:
      // do nothing
    }
  }, [data, loading, error]);

  return { ...state, selectEshop };
}

export function AccountProvider({ children }) {
  const account = useProvideAccount();
  return <accountContext.Provider value={account}>{children}</accountContext.Provider>;
}

AccountProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useAccount = () => useContext(accountContext);
