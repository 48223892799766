import { Link as RouterLink, useSearchParams, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';
// layouts
import { useTranslation } from 'react-i18next';
import LoginForm from '../components/LoginForm';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const password = searchParams.get('password');
  const autoLogin = searchParams.get('autoLogin') === 'true';
  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    navigate({
      pathname: '/'
    });
  };

  return (
    <RootStyle title={t('pages.login')}>
      <AuthLayout>
        {t('login.dont_have_account')} &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/signup">
          {t('login.get_started')}
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {t('login.welcome_text')}
          </Typography>
          <img src="/static/illustrations/illustration_recommender.jpg" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('login.sign_in')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{t('login.enter_details')}</Typography>
          </Stack>

          <LoginForm
            defaultUsername={username}
            defaultPassword={password}
            autoLogin={autoLogin}
            onSubmitSuccess={handleSubmitSuccess}
          />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              {t('login.dont_have_account')}&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                {t('login.get_started')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
