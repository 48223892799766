import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { MotionContainer, varBounceIn } from './animate';

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(20, 20),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
  justifyContent: 'center'
}));

function ContainerMessage({ title, subtitle }) {
  return (
    <AccountStyle>
      <MotionContainer initial="initial" open>
        <Box sx={{ margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounceIn}>
            <Typography variant="h3" paragraph>
              {title}
            </Typography>
          </motion.div>
          <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
        </Box>
      </MotionContainer>
    </AccountStyle>
  );
}
ContainerMessage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

ContainerMessage.defaultProps = {
  subtitle: ''
};

export default ContainerMessage;
