import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot
} from '@material-ui/lab';

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

function OrderItem({ item, isLast }) {
  const { type, title, status } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor:
              (type === 'order1' && 'default.main') ||
              (type === 'order2' && 'success.main') ||
              (type === 'order3' && 'info.main') ||
              (type === 'order4' && 'warning.main') ||
              'secondary.main'
          }}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {status}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default function ModelSetup() {
  return (
    <Box>
      <Typography variant="h6">Beahviour model</Typography>
      <Timeline
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none'
          }
        }}
      >
        <Typography variant="caption">WeightA: 123</Typography>
        <Typography variant="caption">WeightB: 123</Typography>
        <Typography variant="caption">WeightC: 123</Typography>
        <Typography variant="caption">WeightD: 123</Typography>
      </Timeline>
    </Box>
    //   </CardContent>
    // </Card>
  );
}
