import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src="/static/logo_color.svg"
      sx={{
        width: '100%',
        margin: 'auto',
        ...sx,
        maxHeight: '15vh',
        padding: '32px 0'
      }}
    />
  );
}
