import { useRef, useState } from 'react';
// material
import { alpha } from '@material-ui/core/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton, Tooltip } from '@material-ui/core';
// components
import { useTranslation } from 'react-i18next';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

const LANGUAGES = [
  {
    value: 'debug',
    label: 'Debug',
    icon: '/static/icons/ic_flag_en_2.svg'
  },
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en_2.svg'
  },
  // {
  //   value: 'de',
  //   label: 'German',
  //   icon: '/static/icons/ic_flag_de.svg'
  // },
  // {
  //   value: 'fr',
  //   label: 'French',
  //   icon: '/static/icons/ic_flag_fr.svg'
  // },
  {
    value: 'cs',
    label: 'Czech',
    icon: '/static/icons/ic_flag_cs.svg'
  }
];

function availableLanguages() {
  if (process.env.NODE_ENV === 'production') {
    return LANGUAGES.filter((language) => language.value !== 'debug');
  }
  return LANGUAGES;
}

export default function LanguagePopover() {
  const { i18n, t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (language) => {
    console.log('calling handleClose with language:', language);
    setOpen(false);
    i18n.changeLanguage(language);
  };

  const selectedLanguage = LANGUAGES.find((i) => i.value === i18n.language);

  return (
    <>
      <Tooltip title={t('general.language')}>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            marginRight: '1rem',
            width: 22,
            height: 22,
            ...(open && {
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          <img src={selectedLanguage.icon} alt={selectedLanguage.label} />
        </IconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {availableLanguages().map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedLanguage.value}
              onClick={() => handleClose(option.value)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
