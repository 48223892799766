import React from 'react';
import { Box, Grid, Card, CardHeader, CardContent, Container, Typography } from '@material-ui/core';
import Page from '../components/Page';
import ModelSetup from '../components/ModelSetup';
import ProductList from '../components/ProductList';
import PRODUCTS from '../_mocks_/products';
import PlaygroundForm from '../components/PlaygroundForm';

export default function Settings() {
  return (
    <Page title="Settings">
      <Container>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Playground</Typography>
        </Box>

        <Grid container spacing={6}>
          <Grid item xs={12} md={8} lg={8}>
            <Card
              sx={{
                '& .MuiTimelineItem-missingOppositeContent:before': {
                  display: 'none'
                }
              }}
            >
              <CardHeader title="Page view attributes" />
              <CardContent>
                <PlaygroundForm />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Card
              sx={{
                '& .MuiTimelineItem-missingOppositeContent:before': {
                  display: 'none'
                }
              }}
            >
              <CardHeader title="Model setup" />
              <CardContent>
                <ModelSetup />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <ProductList products={[PRODUCTS[0], PRODUCTS[1], PRODUCTS[2], PRODUCTS[3]]} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
