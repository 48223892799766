import PropTypes from 'prop-types';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@material-ui/core';
//
import { BaseOptionChart } from './charts';

function SingleChart({ title, subtitle, seriesLabel, dataSeries, labels, isPercentage }) {
  const CHART_DATA = [
    {
      name: seriesLabel,
      type: 'area',
      data: dataSeries
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [2] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['gradient', 'gradient'] },
    labels: [...labels],
    xaxis: { type: 'datetime' },
    yaxis: [
      {
        title: {
          text: seriesLabel
        },
        labels: {
          formatter: (y) => {
            if (isPercentage) {
              if (typeof y !== 'undefined') {
                return `${(y.toFixed(2) * 100).toFixed(0)}%`;
              }
              return y;
            }
            return null;
          }
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (isPercentage) {
            if (typeof y !== 'undefined') {
              return `${(y.toFixed(2) * 100).toFixed(0)}%`;
            }
            return y;
          }
          return null;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title={title} subheader={subtitle} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}

SingleChart.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  seriesLabel: PropTypes.string,
  isPercentage: PropTypes.bool,
  dataSeries: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
};

SingleChart.defaultProps = {
  subtitle: ''
};

export default SingleChart;
