import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { LinearProgress, Typography } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';

const LIST_STATISTICS = gql`
  query listStatistics($domain: ID!, $from: String!, $to: String) {
    listStatistics(domain: $domain, typeDateKey: { between: [$from, $to] }) {
      items {
        productViews
        addedToCart
        orders
      }
    }
  }
`;

function LearningProgress({ domain }) {
  const { t } = useTranslation();
  const { data } = useQuery(LIST_STATISTICS, {
    variables: {
      domain,
      from: 'daily-2021-01-01',
      to: `daily-${new Date().toISOString().split('T')[0]}`
    }
  });

  if (!data) {
    return null;
  }

  const processedEvents = calculateProcessedEvents(data);
  const progress = 1 - Math.exp(-0.008 * processedEvents);

  return (
    <>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{
          mb: 0
        }}
      >
        {t('eshop.analyzed_events', {
          numberOfEvents: processedEvents
        })}
      </Typography>
      <LinearProgress variant="determinate" color="info" value={progress * 100} />
    </>
  );
}

LearningProgress.propTypes = {
  domain: PropTypes.string.isRequired
};

export default LearningProgress;

function calculateProcessedEvents(data) {
  const sum = data.listStatistics.items.reduce(
    (prev, curr) => prev + curr.addedToCart + curr.orders + curr.productViews,
    0
  );
  return sum;
}
