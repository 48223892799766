import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    // width: '80%'
  }
});

function valuetext(value) {
  return `${value}°C`;
}

export default function DiscreteSlider2() {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      // style={{
      //   padding: '1rem'
      // }}
    >
      <Typography id="discrete-slider" gutterBottom>
        Number of recommendet products
      </Typography>
      <Slider
        defaultValue={1}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={null}
        marks={[
          {
            value: 1,
            label: '1 item'
          },
          {
            value: 2,
            label: '2 items'
          },
          {
            value: 3,
            label: '3 items'
          },
          {
            value: 4,
            label: '4 items'
          },
          {
            value: 5,
            label: '5 items'
          }
        ]}
        // marks={[
        //   {
        //     value: 1,
        //     label: '1h'
        //   },
        //   {
        //     value: 2,
        //     label: '2h'
        //   }
        // ]}
        min={1}
        max={5}
      />
    </div>
  );
}
