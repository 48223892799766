import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// material
import { Stack, TextField } from '@material-ui/core';

// ----------------------------------------------------------------------

function AccountForm({ defaultValues }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  const onSubmit = () => {};

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField
          {...register('username', {
            required: {
              value: true,
              message: t('form.required')
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t('form.must_be_valid_email')
            }
          })}
          onBlur={() => trigger('username')}
          fullWidth
          autoComplete="username"
          type="email"
          label={t('login.username')}
          placeholder={t('login.username_placeholder')}
          disabled
          error={Boolean(errors?.username?.message)}
          helperText={errors?.username?.message}
        />
      </Stack>
    </form>
  );
}

AccountForm.propTypes = {
  defaultValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired
  })
};

AccountForm.defaultProps = {};

export default AccountForm;
