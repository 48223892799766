import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from '@material-ui/core/Switch';
// material
import { Alert, Stack, TextField, Typography, Slider, Select, MenuItem } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { platforms } from '../utils/variables';

function EshopForm({ defaultValues, loading, onSubmit, isReady }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger
  } = useForm({
    defaultValues
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <input {...register('id')} fullWidth type="hidden" />
        <TextField
          {...register('name', {
            required: {
              value: true,
              message: t('form.required')
            }
          })}
          onBlur={() => trigger('name')}
          fullWidth
          type="text"
          label={t('settings.eshop_name')}
          placeholder={t('settings.eshop_name_placeholder')}
          error={Boolean(errors?.name?.message)}
          helperText={errors?.name?.message}
          disabled={loading}
        />
        <TextField
          {...register('url', {
            required: {
              value: true,
              message: t('form.required')
            },
            pattern: {
              value:
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm,
              message: t('form.must_be_valid_url')
            }
          })}
          onBlur={() => trigger('url')}
          fullWidth
          type="text"
          label={t('settings.url')}
          placeholder={t('settings.url_placeholder')}
          error={Boolean(errors?.url?.message)}
          helperText={errors?.url?.message}
          disabled={loading}
        />
        {/* {(defaultValues.platform !== 'shoptet' || defaultValues.platform !== 'upgates') && (
          <TextField
            {...register('feedUrl', {
              required: {
                value: true,
                message: t('form.required')
              },
              pattern: {
                value:
                  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm,
                message: t('form.must_be_valid_url')
              }
            })}
            onBlur={() => trigger('feedUrl')}
            fullWidth
            type="text"
            label={t('settings.feed_url')}
            placeholder={t('settings.feed_url_placeholder')}
            error={Boolean(errors?.feedUrl?.message)}
            helperText={errors?.feedUrl?.message}
            disabled={loading}
          />
        )} */}
        <Select
          {...register('platform', {
            required: {
              value: true,
              message: t('form.required')
            }
          })}
          onBlur={() => trigger('platform')}
          value={defaultValues.platform}
          label={t(`settings.platform`)}
          // onChange={() => { }}
          error={Boolean(errors?.platform?.message)}
          helperText={errors?.platform?.message}
          disabled
        >
          {platforms.map(({ value, name, disabled }) => (
            <MenuItem key={value} value={value} disabled={disabled}>
              {t('platform', { context: name })}
            </MenuItem>
          ))}
        </Select>
        <Typography id="discrete-slider" gutterBottom>
          {t('settings.update_frequency')}
        </Typography>
        <Slider
          {...register('feedUpdateFrequency', {
            required: {
              value: true,
              message: t('form.required')
            }
          })}
          onBlur={() => trigger('feedUpdateFrequency')}
          error={Boolean(errors?.feedUpdateFrequency?.message)}
          helperText={errors?.feedUpdateFrequency?.message}
          // getAriaValueText={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={null}
          defaultValue={defaultValues?.feedUpdateFrequency}
          marks={[
            {
              value: 1,
              label: '1h'
            },
            {
              value: 3,
              label: '3h'
            },
            {
              value: 6,
              label: '6h'
            },
            {
              value: 12,
              label: '12h'
            },
            {
              value: 24,
              label: '24h'
            }
          ]}
          min={1}
          max={24}
          disabled={loading}
        />
        <Typography
          variant="h5"
          paragraph
          sx={{
            marginTop: '4rem !important'
          }}
        >
          {t('settings.show_recommendations_title')}
        </Typography>
        {!isReady && (
          <Alert severity="info">{t('settings.show_recommendations_description')}</Alert>
        )}
        <FormControlLabel
          control={
            <Switch
              {...register('showRecommendations')}
              error={Boolean(errors?.showRecommendations?.message)}
              helperText={errors?.showRecommendations?.message}
              onBlur={() => trigger('showRecommendations')}
              disabled={loading || !isReady}
              defaultChecked={defaultValues?.showRecommendations === true}
            />
          }
          label={t('settings.show_recommendations')}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          sx={{
            textTransform: 'none'
          }}
        >
          {t('settings.save_changes')}
        </LoadingButton>
      </Stack>
    </form>
  );
}

EshopForm.propTypes = {
  defaultValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    feedUrl: PropTypes.string.isRequired,
    feedUpdateFrequency: PropTypes.number.isRequired,
    platform: PropTypes.string.isRequired,
    showRecommendations: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired
  }),
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  isReady: PropTypes.bool
};

EshopForm.defaultProps = {
  defaultValues: {},
  loading: false,
  isReady: false
  // onSubmit: () => { }
};

export default EshopForm;
