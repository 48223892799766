// i18nStr returns snake cased version of string suitable for i18n
export function i18nStr(input) {
  if (typeof input !== 'string') {
    return input;
  }
  return toSnakecase(input);
}

function toSnakecase(str) {
  return str
    .replaceAll(' ', '_')
    .replaceAll('.', '')
    .replaceAll(':', '')
    .replaceAll(',', '')
    .replaceAll('/', '_')
    .toLowerCase();
}
