import { useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import FullscreenLoader from '../../components/FullscreenLoader';
import FullscreenError from '../../components/FullscreenError';
import { useAccount } from '../../hooks/useAccount';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { i18nStr } from '../../utils/i18nStr';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function DashboardLayout() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const { loading, error, eshop } = useAccount();

  if (error) {
    return <FullscreenError message={t(`errors.${i18nStr(error.message)}`)} />;
  }
  if (loading) {
    return <FullscreenLoader />;
  }
  if (!eshop && pathname !== '/dashboard/eshop-not-found') {
    return <Navigate to="/dashboard/eshop-not-found" />;
  }
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
