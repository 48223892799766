import React from 'react';
import { Box, Grid, Card, CardHeader, CardContent, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import EshopStatus from '../components/EshopStatus';
import EditEshopForm from '../components/EditEshopForm';
import { useAccount } from '../hooks/useAccount';

export default function Settings() {
  const { t } = useTranslation();
  const {
    eshop: { id, domain }
  } = useAccount();

  return (
    <Page title={t('pages.settings')}>
      <Container>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{t('general.settings')}</Typography>
        </Box>

        <Grid container spacing={6}>
          <Grid item xs={12} md={8} lg={8}>
            <Card
              sx={{
                '& .MuiTimelineItem-missingOppositeContent:before': {
                  display: 'none'
                }
              }}
            >
              <CardHeader title={t('settings.eshop')} />
              <CardContent>
                <EditEshopForm id={id} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <EshopStatus id={id} domain={domain} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
