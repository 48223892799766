import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Alert, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { confirmSignUp } from '../utils/auth';
import { i18nStr } from '../utils/i18nStr';

// ----------------------------------------------------------------------

function ConfirmSignUpForm({ onSubmitSuccess, defaultUsername }) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: defaultUsername && {
      username: defaultUsername
    }
  });

  const onSubmit = async (data) => {
    const { username, code } = data;

    setIsSubmitting(true);
    try {
      await confirmSignUp(username, code);
      setSubmitError(null);
      toast.success(t('confirm_signup.submit_success'));
      onSubmitSuccess();
    } catch (error) {
      setSubmitError(error.message);
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ mb: 2 }}>
        {defaultUsername && !submitError && (
          <Alert severity="info">
            {t(`confirm_signup.check_verification_code`, {
              email: defaultUsername
            })}
          </Alert>
        )}
        {submitError && <Alert severity="error">{t(`errors.${i18nStr(submitError)}`)}</Alert>}

        <TextField
          {...register('username', {
            required: {
              value: true,
              message: t('form.required')
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t('form.must_be_valid_email')
            }
          })}
          onBlur={() => trigger('username')}
          disabled={defaultUsername}
          fullWidth
          autoComplete="username"
          type="email"
          label={t('confirm_signup.username')}
          placeholder={t('confirm_signup.username_placeholder')}
          error={Boolean(errors?.username?.message)}
          helperText={errors?.username?.message}
          defaultValue={defaultUsername}
        />
        <TextField
          {...register('code', {
            required: {
              value: true,
              message: t('form.required')
            },
            pattern: {
              value: /^[0-9]{6}$/,
              message: t('form.6_digit_code')
            }
          })}
          onBlur={() => trigger('code')}
          fullWidth
          autoComplete="code"
          type="text"
          label={t('confirm_signup.code')}
          placeholder={t('confirm_signup.code_placeholder')}
          error={Boolean(errors?.code?.message)}
          helperText={errors?.code?.message}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {t('confirm_signup.confirm')}
      </LoadingButton>
    </form>
  );
}

ConfirmSignUpForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  defaultUsername: PropTypes.string
};

ConfirmSignUpForm.defaultProps = {
  onSubmitSuccess: () => ({}),
  defaultUsername: ''
};

export default ConfirmSignUpForm;
