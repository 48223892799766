import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Alert, Stack, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { forgotPassword, forgotPasswordSubmit } from '../utils/auth';
import { i18nStr } from '../utils/i18nStr';

// ----------------------------------------------------------------------

function ForgotPasswordForm({ onSubmitSuccess }) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const { register, handleSubmit, errors, trigger, getValues } = useForm();

  const onSubmit = async (data) => {
    const { username, code, password, passwordCheck } = data;

    setIsSubmitting(true);
    if (!codeSent) {
      try {
        await forgotPassword(username);
        setSubmitError(null);
        setCodeSent(true);
        setIsSubmitting(false);
      } catch (error) {
        setSubmitError(error.message);
        setIsSubmitting(false);
      }
    } else {
      try {
        // check if passwords same
        if (password !== passwordCheck) {
          setSubmitError(t('forgot_password.passwordcheck_not_same_as_password'));
          setIsSubmitting(false);
          return;
        }
        await forgotPasswordSubmit(username, code, password);
        setIsSubmitting(false);
        toast.success(t('forgot_password.submit_success'));
        onSubmitSuccess({ username });
      } catch (error) {
        setSubmitError(error.message);
        setIsSubmitting(false);
      }
    }
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ mb: 2 }}>
        {codeSent && (
          <Alert severity="info">
            {t(`forgot_password.check_verification_code`, {
              email: getValues('username') || ''
            })}
          </Alert>
        )}
        {submitError && <Alert severity="error">{t(`errors.${i18nStr(submitError)}`)}</Alert>}

        <TextField
          {...register('username', {
            required: {
              value: true,
              message: t('form.required')
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t('form.must_be_valid_email')
            }
          })}
          disabled={codeSent}
          onBlur={() => trigger('username')}
          fullWidth
          autoComplete="username"
          type="email"
          label={t('forgot_password.username')}
          placeholder={t('forgot_password.username_placeholder')}
          error={Boolean(errors?.username?.message)}
          helperText={errors?.username?.message}
        />

        {codeSent && (
          <>
            <TextField
              {...register('code', {
                required: {
                  value: true,
                  message: t('form.required')
                }
              })}
              onBlur={() => trigger('code')}
              fullWidth
              autoComplete="code"
              type="text"
              label={t('forgot_password.code')}
              placeholder={t('forgot_password.code_placeholder')}
              error={Boolean(errors?.code?.message)}
              helperText={errors?.code?.message}
            />

            <TextField
              {...register('password', {
                required: {
                  value: true,
                  message: t('form.required')
                }
              })}
              onBlur={() => trigger('password')}
              fullWidth
              autoComplete="password"
              type={showPassword ? 'text' : 'password'}
              label={t('forgot_password.password')}
              placeholder={t('forgot_password.password_placeholder')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors?.password?.message)}
              helperText={errors?.password?.message}
            />

            <TextField
              {...register('passwordCheck', {
                required: {
                  value: true,
                  message: t('form.required')
                }
              })}
              onBlur={() => trigger('passwordCheck')}
              fullWidth
              autoComplete="passwordCheck"
              type={showPassword ? 'text' : 'password'}
              label={t('forgot_password.password')}
              placeholder={t('forgot_password.password_placeholder')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors?.password?.message)}
              helperText={errors?.password?.message}
            />
          </>
        )}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {!codeSent ? t('forgot_password.send_code') : t('forgot_password.reset_password')}
      </LoadingButton>
    </form>
  );
}

ForgotPasswordForm.propTypes = {
  onSubmitSuccess: PropTypes.func
};

ForgotPasswordForm.defaultProps = {
  onSubmitSuccess: () => ({})
};

export default ForgotPasswordForm;
