import React, { createContext, useContext, useEffect, useState, useReducer } from 'react';
import { Auth, Hub } from 'aws-amplify';
import PropTypes from 'prop-types';

const initialState = {
  loading: true,
  error: undefined,
  user: undefined
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_DATA_INIT':
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case 'FETCH_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        user: action.payload.user
      };
    case 'FETCH_DATA_FAILURE':
      return { ...state, loading: false, error: action.payload.error };
    case 'RESET_DATA':
      return { ...state, user: undefined };
    default:
      throw new Error(`unsupported action type: ${action.type}`);
  }
};

function useProvideAuth() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [triggerFetch, setTriggerFetch] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      if (isMounted && !state.loading) {
        dispatch({ type: 'FETCH_DATA_INIT' });
      }
      try {
        if (isMounted) {
          const data = await Auth.currentAuthenticatedUser();
          if (data) {
            dispatch({
              type: 'FETCH_DATA_SUCCESS',
              payload: { user: data }
            });
          }
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: 'FETCH_DATA_FAILURE', payload: { error } });
        }
      }
    };

    const onAuthEvent = (payload) => {
      switch (payload.event) {
        case 'signIn':
          if (isMounted) {
            setTriggerFetch(true);
          }
          break;
        default:
      }
    };

    const HubListener = () => {
      Hub.listen('auth', (data) => {
        const { payload } = data;
        onAuthEvent(payload);
      });
    };

    HubListener();
    fetchUserData();

    return () => {
      Hub.remove('auth');
      isMounted = false;
    };
  }, [triggerFetch]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setTriggerFetch(false);
      dispatch({ type: 'RESET_DATA' });
    } catch (error) {
      console.error('Error signing out user ', error);
    }
  };

  const handleSignIn = async ({ username, password }) => {
    const user = await Auth.signIn({
      username,
      password
    });
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await Auth.completeNewPassword(user, password, {});
    }
  };

  const handleSignUp = async () => {
    console.log('signing up...');
  };

  return { ...state, handleSignOut, handleSignIn, handleSignUp };
}

const authContext = createContext();
authContext.displayName = 'AuthContext';

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useAuth = () => useContext(authContext);
